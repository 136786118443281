import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({ to, children, ...props }) => {
  
  const smoothScrollToID = (e, href) => {
    e.preventDefault()
    const id = href.replace('#', '')
    const el = document.getElementById(id)
    const offset = window.innerWidth < 900 ? 56 : 72
    const position = el.offsetTop - offset
    window.scrollTo({
      top: position,
      behavior: 'smooth'
    })
  }

  const href = to?.replace(process.env.GATSBY_PREVIEW_URL, '') || ''

  const offsite = ['//', 'mailto:', 'tel:', 'sms:'].findIndex(el => href.indexOf(el) !== -1)

  // if is a hash link window animate scroll to the ID
  if (href.indexOf('#') === 0) {
    return <a onClick={(e) => smoothScrollToID(e, href)} {...props}>{ children }</a>
  }

  if (offsite === -1) {
    return <GatsbyLink to={href} {...props}>{ children }</GatsbyLink>
  }

  return (
    <a {...props} href={href} target='_blank' rel='nofollow noopener noreferrer'>
      { children }
    </a>
  )
}

export default Link
